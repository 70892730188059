import { createSlice } from "@reduxjs/toolkit";

const refreshFilter = (filter) => {
  let filterQuery = {
    nameDataRegex: filter.nameDataRegex,
    data: {
      status: filter.data.status,
    },
  };

  filterQuery = JSON.parse(JSON.stringify(filterQuery));
  
  Object.keys(filterQuery)
    .forEach(k => Object.keys(filterQuery[k]).length === 0 && delete filterQuery[k]);
  
  return filterQuery;
};

export const initialState = {
  lastUpdated: null,
  count: null,
  data: null,
  filter: {
    nameDataRegex: undefined,
    data: {
      status: undefined,
    },
  },
  get filterQuery() {
    return refreshFilter(this.filter);
  },
  details: {},
  ACLModal: {
    activeListing: null,
    details: {},
  },
  settings: null,
  settingsAutopilot: "Individual Contributor",
  settingsLoading: false,
};

export const jobs = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    updateFilterStatus: (state, { payload }) => {
      state.filter.data.status = payload;
      state.filterQuery = refreshFilter(state.filter);
    },
    updateFilterName: (state, { payload }) => {
      state.filter.nameDataRegex = payload === "" ? undefined : payload;
      state.filterQuery = refreshFilter(state.filter);
    },
    resetFilter: (state) => {
      state.filter = initialState.filter;
      state.filterQuery = refreshFilter(state.filter);
    },
    updateCount: (state, { payload }) => {
      state.count = payload;
    },
    updateData: (state, { payload }) => {
      state.data = payload;
    },
    updateDetails: (state, { payload }) => {
      state.details = payload;
      state.settingsLoading = payload?.updating;
      state.lastUpdated = payload?.lastUpdated;

      if (state.settings === null) {
        const settings = { ...payload.settings };
        settings.requirements.flags = settings.requirements.flags.map((x) => [x, 1]);
        settings.requirements.negations = settings.requirements.negations.map((x) => [x, 1]);

        if (!settings.requirements?.seniorities?.[0]) {
          settings.requirements.seniorities[0] = "Individual Contributor";
        }
  
        state.settings = settings;
      }
    },
    updateSettingsWeightages: (state, { payload }) => {
      const [pillar, value] = payload;
      state.settings.weightages[pillar] = value;
    },
    updateSettingsRequirements: (state, { payload }) => {
      const [pillar, value] = payload;
      state.settings.requirements[pillar] = value;
    },
    updateSettingsAutopilot: (state, { payload }) => {
      const settings = { ...state.settings };
      settings.requirements.seniorities[0] = payload;
      state.settings = settings;
    },
    toggleACLModal: (state, { payload }) => {
      state.ACLModal.activeListing = payload;
    },
    updateACL: (state, { payload }) => {
      state.ACLModal.details = payload;
    },
    updateSettings: (state, { payload }) => {
      const [field, key, value] = payload;
      state.settings[field][key] = value;
    },
    updateSettingsLoading: (state, { payload }) => {
      state.settingsLoading = payload;
    },
    reset: (state, { payload }) => {
      state.lastUpdated = null;
      state.count = null;
      state.data = null;
      state.filter = {
        nameDataRegex: undefined,
        data: {
          status: undefined,
        },
      };
      state.details = null;
      state.settings = null;
      state.settingsAutopilot = "Individual Contributor";
      state.settingsLoading = false;
    },
  },
});

export const jobsReducers = jobs.actions;

export default jobs.reducer;
