import { createSlice } from "@reduxjs/toolkit";

const filterJobStats = {
  filterAlumniCount: {
    type: "alumni",
  },
  filterEmployeeCount: {
    type: "employee",
  },
  filterTalentCount: {
    type: "talent",
  },
  filterProspectCount: {
    type: "prospect",
  },
  filterApplicantCount: {
    type: "applicant",
  },
  filterRejectedCount: {
    type: "rejected",
  },
  filterHiredCount: {
    type: "hired",
  },
  filterTop: {
    _operators: {
      qualified: {
        lte: 1.1,
      },
    },
  },
};

const refreshFilter = (filter) => {
  let filterQuery = {
    jobId: filter.jobId,
    type: filter.type,
    nameRegex: filter.nameRegex,
    locationRegex: filter.locationRegex,
    resumeSource: filter.resumeSource,
    workPass: filter.workPass,
    _operators: {
      qualified: JSON.parse(filter.qualified),
    },
    AND: [
      {
        OR: filter.priority.length
          ? filter.priority.map(condition => ({
            _operators: {
              score: JSON.parse(condition),
            },
          }))
          : [
            {
              _operators: {
                score: { gte: -1, lte: 1 },
              },
            },
          ],
      },
    ],
  };

  filterQuery = JSON.parse(JSON.stringify(filterQuery));
  
  Object.keys(filterQuery)
    .forEach(k => Object.keys(filterQuery[k]).length === 0 && delete filterQuery[k]);
  
  return filterQuery;
};

const initialState = {
  tab: "screening",
  sort: "SCORE_DESC",
  filterJobStats,
  filter: {
    jobId: "",
    type: "applicant",
    priority: [],
    qualified: JSON.stringify({
      gte: 0, lte: 1000,
    }),
    resumeSource: undefined,
    workPass: undefined,
    locationRegex: undefined,
    nameRegex: undefined,
  },
  get filterQuery() {
    return refreshFilter(this.filter);
  },
  counts: {},
  top: null,
  table: {
    count: null,
    data: [],
    page: 0,
    pages: 0,
  },
  offcanvas: {
    activeIndex: null,
    activeId: null,
    activeTab: "resume",
  },
  pageSize: 10,
};

export const distances = createSlice({
  name: "distances",
  initialState,
  reducers: {
    updateTab: (state, { payload }) => {
      state.tab = payload;
    },
    updateSort: (state, { payload }) => {
      state.sort = payload;
    },
    updateJobFilter: (state, { payload }) => {
      state.offcanvas.activeId = null;
      state.offcanvas.activeIndex = null;

      state.filter.jobId = payload;

      const o = { ...filterJobStats };
      Object.keys(o).forEach((k) => { o[k] = { jobId: payload, ...o[k] }; });

      state.filterJobStats = o;

      state.filterQuery = refreshFilter(state.filter);
    },
    updateFilterPriority: (state, { payload }) => {
      state.offcanvas.activeId = null;
      state.offcanvas.activeIndex = null;

      state.filter.priority = payload;
      state.filterQuery = refreshFilter(state.filter);
      state.table.page = 0;
    },
    updateFilterType: (state, { payload }) => {
      state.offcanvas.activeId = null;
      state.offcanvas.activeIndex = null;

      state.filter.type = payload;
      state.filterQuery = refreshFilter(state.filter);
      state.table.page = 0;
    },
    updateFilterWorkPass: (state, { payload }) => {
      state.offcanvas.activeId = null;
      state.offcanvas.activeIndex = null;

      state.filter.workPass = payload;
      state.filterQuery = refreshFilter(state.filter);
      state.table.page = 0;
    },
    updateFilterResumeSource: (state, { payload }) => {
      state.offcanvas.activeId = null;
      state.offcanvas.activeIndex = null;

      state.filter.resumeSource = payload;
      state.filterQuery = refreshFilter(state.filter);
      state.table.page = 0;
    },
    updateFilterLocation: (state, { payload }) => {
      state.offcanvas.activeId = null;
      state.offcanvas.activeIndex = null;

      state.filter.locationRegex = payload;
      state.filterQuery = refreshFilter(state.filter);
      state.table.page = 0;
    },
    updateFilterQualified: (state, { payload }) => {
      state.offcanvas.activeId = null;
      state.offcanvas.activeIndex = null;

      state.filter.qualified = payload;
      state.filterQuery = refreshFilter(state.filter);
      state.table.page = 0;
    },
    updateFilterName: (state, { payload }) => {
      state.offcanvas.activeId = null;
      state.offcanvas.activeIndex = null;

      state.filter.nameRegex = payload;
      state.filterQuery = refreshFilter(state.filter);
      state.table.page = 0;
    },
    updateCounts: (state, { payload }) => {
      state.counts = {
        total: payload["countTotal"],
        alumni: payload["countAlumni"],
        employee: payload["countEmployee"],
        talent: payload["countTalent"],
        prospect: payload["countProspect"],
        applicant: payload["countApplicant"],
        rejected: payload["countRejected"],
        hired: payload["countHired"],
      };
    },
    updateApplicantsCount: (state, { payload }) => {
      state.table.count = payload;
      state.table.pages = Math.ceil(payload / 10) || 1;
    },
    updateApplicantsTop: (state, { payload }) => {
      state.top = payload;
    },
    updateApplicantsData: (state, { payload }) => {
      state.table.data = payload;
    },
    updateApplicantsPage: (state, { payload }) => {
      state.table.page = payload;
    },
    resetFilter: (state) => {
      state.filter = initialState.filter;
      state.table.page = 0;
    },
    updateOffcanvasActive: (state, { payload }) => {
      state.offcanvas.activeIndex = payload;
    },
    updateOffcanvasId: (state, { payload }) => {
      state.offcanvas.activeId = payload;
    },
    updateOffcanvasTab: (state, { payload }) => {
      state.offcanvas.activeTab = payload;
    },
    reset: (state, { payload }) => {
      state.tab = "screening";
      state.sort = "SCORE_DESC";
      state.filter = {
        jobId: "",
        type: "applicant",
        priority: [],
        qualified: JSON.stringify({
          gte: 0, lte: 1000,
        }),
        resumeSource: undefined,
        workPass: undefined,
        locationRegex: undefined,
        nameRegex: undefined,
      };
      state.counts = {};
      state.top = null;
      state.table = {
        count: null,
        data: [],
        page: 0,
        pages: 0,
      };
    },
  },
});

export const distancesReducers = distances.actions;

export default distances.reducer;
