import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ipv4: null,
  loading: {
    button: false,
  },
  error: {
    message: null,
  },
  activity: null,
};

export const app = createSlice({
  name: "app",
  initialState,
  reducers: {
    updateIpv4: (state, { payload }) => {
      state.ipv4 = payload;
    },
    toggleLoadingButton: (state, { payload }) => {
      state.loading.button = payload;
    },
    updateErrorMessage: (state, { payload }) => {
      state.error.message = payload;
    },
    toggleActivity: (state, { payload }) => {
      state.activity = payload;
    },
  },
});

export const appReducers = app.actions;

export default app.reducer;
