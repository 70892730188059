import layout from "./reducers/layout";
import app from "./reducers/app";
import session from "./reducers/session";
import dashboard from "./reducers/dashboard";
import companies from "./reducers/companies";
import jobs from "./reducers/jobs";
import distances from "./reducers/distances";
import contacts from "./reducers/contacts";
import composer from "./reducers/composer";

export { layoutReducers } from "./reducers/layout";
export { appReducers } from "./reducers/app";
export { sessionReducers } from "./reducers/session";
export { dashboardReducers } from "./reducers/dashboard";
export { companiesReducers } from "./reducers/companies";
export { jobsReducers } from "./reducers/jobs";
export { distancesReducers } from "./reducers/distances";
export { contactsReducers } from "./reducers/contacts";
export { composerReducers } from "./reducers/composer";

const rootReducer = {
  layout,
  app,
  session,
  dashboard,
  companies,
  jobs,
  distances,
  contacts,
  composer,
};

export default rootReducer;
