import { createSlice } from "@reduxjs/toolkit";

import htmlToDraft from "html-to-draftjs";
import { EditorState, ContentState } from "draft-js";

const contentBlock = htmlToDraft("");
const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
const editorState = EditorState.createWithContent(contentState);

const initialState = {
  modalOpen: false,
  modalMaximized: false,
  type: null,
  email: {
    state: {
      ccOpen: false,
      bccOpen: false,
      sendSeparate: false,
      attachmentSize: 0,
    },
    form: {
      to: [],
      cc: [],
      bcc: [],
      subject: "",
      content: editorState,
      attachments: [],
    },
  },
  note: {
    form: {
      content: editorState,
      attachments: [],
    },
  },
};

export const composer = createSlice({
  name: "composer",
  initialState,
  reducers: {
    composeOpen: (state, { payload }) => {
      state.modalOpen = true;
      state.type = payload.type;

      if (payload.type === "email" && payload.email) {
        state.email.form.to = [
          {
            label: payload.name,
            value: payload.email,
          },
        ];
      }
    },
    toggleModalSize: (state) => {
      state.modalMaximized = !state.modalMaximized;
    },
    toggleEmailCC: (state) => {
      state.email.state.ccOpen = !state.email.state.ccOpen;
    },
    toggleEmailBCC: (state) => {
      state.email.state.bccOpen = !state.email.state.bccOpen;
    },
    toggleEmailSendSeparate: (state) => {
      state.email.state.sendSeparate = !state.email.state.sendSeparate;
    },
    updateEmailForm: (state, { payload }) => {
      const { key, value } = payload;
      state.email.form[key] = value;
    },
    addEmailAttachments: (state, { payload }) => {
      state.email.form.attachments = [
        ...state.email.form.attachments,
        payload,
      ];
    },
    updateAttachmentSize: (state, { payload }) => {
      state.email.state.attachmentSize = payload;
    },
    removeEmailAttachments: (state, { payload }) => {
      const attachments = [...state.email.form.attachments];
      attachments.splice(payload, 1);
      state.email.form.attachments = attachments;
    },
    updateNoteForm: (state, { payload }) => {
      const { key, value } = payload;
      state.note.form[key] = value;
    },
    addNoteAttachments: (state, { payload }) => {
      state.note.form.attachments = [
        ...state.note.form.attachments,
        payload,
      ];
    },
    removeNoteAttachments: (state, { payload }) => {
      const attachments = [...state.note.form.attachments];
      attachments.splice(payload, 1);
      state.note.form.attachments = attachments;
    },
    composeMinimize: (state) => {
      state.type = null;
      state.modalOpen = false;
    },
    composeClose: (state) => {
      state.type = null;
      state.modalOpen = false;
      state.form = initialState.form;
    },
  },
});

export const composerReducers = composer.actions;

export default composer.reducer;
