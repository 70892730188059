import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  type: "pipeline",
  nodes: [],
  edges: [],
};

export const dashboard = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    updateType: (state, { payload }) => {
      state.type = payload;
    },
    updateNodes: (state, { payload }) => {
      state.nodes = payload;
    },
    updateEdges: (state, { payload }) => {
      state.edges = payload;
    },
  },
});

export const dashboardReducers = dashboard.actions;

export default dashboard.reducer;
