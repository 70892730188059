import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bouncing: true,
  cookie: {},
  account: {},
};

export const session = createSlice({
  name: "session",
  initialState,
  reducers: {
    toggleBouncer: (state, { payload }) => {
      state.bouncing = payload;
    },
    setCookie: (state, { payload }) => {
      state.cookie = payload;
    },
    clearCookie: (state) => {
      state.cookie = {};
    },
    updateAccount: (state, { payload }) => {
      state.account = payload;
    },
  },
});

export const sessionReducers = session.actions;

export default session.reducer;
