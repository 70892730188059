import { v4 as uuidv4 } from "uuid";
import { createSlice } from "@reduxjs/toolkit";

const refreshFilter = (filter) => {
  let filterQuery = {
    firstNameDataRegex: filter.firstNameDataRegex,
  };

  filterQuery = JSON.parse(JSON.stringify(filterQuery));
  
  Object.keys(filterQuery)
    .forEach(k => Object.keys(filterQuery[k]).length === 0 && delete filterQuery[k]);
  
  return filterQuery;
};

const initialState = {
  side: "profile",
  activitiesToken: null,
  tab: "activities",
  data: [],
  filter: {
    firstNameDataRegex: undefined,
  },
  get filterQuery() {
    return refreshFilter(this.filter);
  },
  contact: {},
  total: 0,
  conversation: [],
  trackers: {},
  page: 0,
  pageSize: 10,
  pages: 0,
  sidebarOpen: false,
  emailQuery: null,
  reverseMatches: [],
  mails: [],
  notes: [],
  activities: [],
};

export const contacts = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    updateFilterName: (state, { payload }) => {
      state.filter.firstNameDataRegex = payload === "" ? undefined : payload;
      state.filterQuery = refreshFilter(state.filter);
    },
    resetFilter: (state) => {
      state.filter = initialState.filter;
      state.filterQuery = refreshFilter(state.filter);
    },
    updateSide: (state, { payload }) => {
      state.side = payload;
    },
    updateTab: (state, { payload }) => {
      state.tab = payload;
    },
    updateData: (state, { payload }) => {
      state.data = payload;
    },
    updateContact: (state, { payload }) => {
      state.contact = payload;

      const emails = [payload?.email]
        .concat(payload?.emails)
        .map((x) => x?.value)
        .filter(x => !!x);
        
      state.emailQuery = emails.length > 0
        ? emails.join("|")
        : uuidv4();
    },
    updateTotal: (state, { payload }) => {
      state.total = payload;
      state.pages = Math.ceil(state.total / state.pageSize) || 1;
    },
    updateConversation: (state, { payload }) => {
      state.conversation = payload;
    },
    updateTrackers: (state, { payload }) => {
      const trackers = {};

      for (const entry of payload) {
        trackers[entry.mailId] = entry.count;
      }

      state.trackers = trackers;
    },
    updatePage: (state, { payload }) => {
      state.page = payload;
    },
    updatePageSize: (state, { payload }) => {
      state.pageSize = payload;
      state.pages = Math.ceil(state.total / state.pageSize) || 1;
    },
    toggleSidebar: (state, { payload }) => {
      state.sidebarOpen = payload;
    },
    updateMatches: (state, { payload }) => {
      state.reverseMatches = payload;
    },
    updateMails: (state, { payload }) => {
      state.mails = payload;
    },
    updateNotes: (state, { payload }) => {
      state.notes = payload;
    },
    updateActivities: (state) => {
      const notes = state.notes || [];
      const mails = state.mails || [];
      const applications = state.contact?.applications || [];

      const activities = [
        ...notes.map((x) => ({
          date: new Date(x.date),
          activityType: "note",
          activity: x,
        })),
        ...mails.map((x) => ({
          date: new Date(x.date),
          activityType: "email",
          activity: x,
        })),
        ...applications.map((x) => ({
          date: new Date(x.data.createDate),
          activityType: "application",
          activity: x,
        })),
      ].sort((x, y) => y.date - x.date);

      if (activities.length >= state.activities.length) {
        state.activities = activities;
      }
    },
    refreshActivities: (state, { payload }) => {
      state.activitiesToken = payload;
    },
  },
});

export const contactsReducers = contacts.actions;

export default contacts.reducer;
