import {
  ApolloClient,
  InMemoryCache,
} from "@apollo/client";

export const apolloClient = new ApolloClient(
  {
    uri: process.env.REACT_APP_MIDDLEWARE_GRAPHQL_URL,
    cache: new InMemoryCache({
      addTypename: false,
    }),
    credentials: "include",
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "no-cache",
        errorPolicy: "ignore",
        notifyOnNetworkStatusChange: true,
      },
      query: {
        fetchPolicy: "no-cache",
        errorPolicy: "ignore",
        notifyOnNetworkStatusChange: true,
      },
    },
  },
);
